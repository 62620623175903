import { useMutation } from '@apollo/client/index.js';
import { CreateTimeEntryDialog_Save } from '@asaprint/asap/components/dialogs/CreateTimeEntryDialog.graphql';
import TimeEntryForm, { FormValues, validate as formValidate } from '@asaprint/asap/components/forms/TimeEntryForm.js';
import { useAuth } from '@asaprint/asap/contexts/AuthContext.js';
import {
  CreateTimeEntryDialog_SaveMutation,
  CreateTimeEntryDialog_SaveMutationVariables,
  OrderReceivedField_OrderFragment,
  PhaseField_PhaseFragment,
  ProductField_ProductFragment,
} from '@asaprint/asap/schema.client.types.js';
import { toOption as userToOption } from '@asaprint/common/helpers/User.js';
import { ConnectedDialogSubmitButtons } from '@engined/client/components/dialogs/DialogSubmitButtons.js';
import { Option } from '@engined/client/components/forms/fields/AutocompleteField.js';
import Form, { OnSubmit } from '@engined/client/components/forms/Form.js';
import { displayError } from '@engined/client/helpers/errors.js';
import { getLogger } from '@engined/core/services/logger.js';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useMemo } from 'react';

const logger = getLogger('components/modals/CreateTimeEntryDialog');

interface OwnProps {
  open?: boolean;
  product?: Option<ProductField_ProductFragment>;
  orderReceived?: Option<OrderReceivedField_OrderFragment>;
  orderReceivedPhase?: string;
  phase?: Option<PhaseField_PhaseFragment>;
  refetchQueries?: string[];

  onClose(event?: React.MouseEvent<HTMLButtonElement>);
}

type Props = OwnProps;

const CreateTimeEntryDialog: React.FunctionComponent<Props> = ({
  open,
  onClose,
  product,
  orderReceived,
  orderReceivedPhase,
  phase,
  refetchQueries,
}) => {
  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={onClose}>
      <CreateTimeEntryDialogContent
        onClose={onClose}
        product={product}
        orderReceived={orderReceived}
        phase={phase}
        refetchQueries={refetchQueries}
        orderReceivedPhase={orderReceivedPhase}
      />
    </Dialog>
  );
};

CreateTimeEntryDialog.displayName = 'CreateTimeEntryDialog';

export default CreateTimeEntryDialog;

interface CreateTimeEntryDialogContentOwnProps {
  product?: Option;
  orderReceived?: Option;
  orderReceivedPhase?: string;
  phase?: Option;
  refetchQueries?: string[];
  onClose(event?: React.MouseEvent<HTMLButtonElement>);
}

const CreateTimeEntryDialogContent: React.FunctionComponent<CreateTimeEntryDialogContentOwnProps> = ({
  onClose,
  product,
  orderReceived,
  orderReceivedPhase,
  phase,
  refetchQueries,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { requestUser } = useAuth();
  const [saveExecute] = useMutation<CreateTimeEntryDialog_SaveMutation, CreateTimeEntryDialog_SaveMutationVariables>(
    CreateTimeEntryDialog_Save,
    {
      refetchQueries: refetchQueries || ['DayView_Load'],
    },
  );

  const onSubmit: OnSubmit<FormValues> = async ({
    product,
    phase,
    startAt,
    endAt,
    type,
    orderReceived,
    orderReceivedPhase,
    user,
    ...values
  }: FormValues) => {
    try {
      const response = await saveExecute({
        variables: {
          input: {
            ...values,
            product: type === 'product' ? product?.value ?? null : null,
            orderReceived: type === 'orderReceived' ? orderReceived?.value ?? null : null,
            orderReceivedPhase: type === 'orderReceived' ? orderReceivedPhase ?? null : null,
            phase: type === 'product' ? phase?.value ?? null : null,
            user: user?.value ?? null,
            startAt: startAt?.toISOString() ?? null,
            endAt: endAt?.toISOString() ?? null,
          },
        },
      });
      enqueueSnackbar('Úspešne ste uložili strávený čas.', {
        variant: 'success',
      });
      onClose();
    } catch (err) {
      displayError(err, enqueueSnackbar, logger);
    }
  };

  const initialValues = useMemo(
    (): FormValues => ({
      startAt: null,
      endAt: null,
      phase,
      product,
      note: '',
      type: product ? 'product' : orderReceived ? 'orderReceived' : 'none',
      orderReceived,
      orderReceivedPhase,
      user: userToOption(requestUser),
    }),
    [product, orderReceived, orderReceivedPhase, phase, requestUser],
  );

  return (
    <Form defaultValues={initialValues} onSubmit={onSubmit} validate={formValidate}>
      <DialogTitle>Nový záznam</DialogTitle>
      <DialogContent>
        <TimeEntryForm showType={Boolean(!product && !orderReceived)} />
      </DialogContent>
      <ConnectedDialogSubmitButtons closeOnClick={() => onClose()} submitLabel="Pridať čas" />
    </Form>
  );
};

CreateTimeEntryDialogContent.displayName = 'CreateTimeEntryDialogContent';
